.book {
    background-color: #A9A9A9;
    width: 100%;
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    padding-top: calc(var(--navbar-height))
}

.bookings_content_container{
    background-color: #444444;
    position: absolute;
    top: calc( var(--navbar-height) + 60px);
    left: 60px; 
    right: 60px;
    bottom: 60px;
    padding: 60px;
    font-family: 'K2D', sans-serif;
    border-radius: 0.5rem;
}
.bookings_image_container{
    background-color: rgb(53, 53, 53);
    border-radius: 0.5rem;
    width: 300px;
    height: 400px;
    position: absolute;
    top: calc(var(--navbar-height) + 20px);
    right: 20px;
    z-index: 1;
}
.profileImage{
    width: 100%;
    height: 100%;
    border-radius: 0.2rem;
    object-fit: cover;
    animation: fadeIn 0.3s;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.bookings_header {
    color: white;
    font-size: 3rem;
    font-weight: 500;

    text-align: left;

}
.bookings_content{
    margin-top: 1rem;
    box-sizing: border-box; 

}
.content_description{
    text-align: left;
    color: white;
    font-size: 1.1rem;
    /* background-color: green; */
}
.calendar_container{

    margin-top: 1rem;
    
    border-radius: 0.5rem;



}
.calander_row_group{
    display: flex;
    align-items: flex-end;
}

.picker_row{
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
}
.form_row{   
    text-align: left;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.form_label{
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 1.2rem;
}
.form_input{
    width: 100%;
    height: 40px;
    border-radius: 0.2rem;
    outline: none;
    border: none;

    font-size: 1rem;
    font-weight: 300;

    font-family: 'K2D', sans-serif;
    box-sizing: border-box; 
}
.form_text_area{
    width: 100%;
    height: 100px;
    border-radius: 0.2rem;
    outline: none;
    border: none;
    padding: 0.5rem;

    font-size: 1rem;
    font-weight: 300;
    resize: none;
    text-align: left;

    font-family: 'K2D', sans-serif;
    box-sizing: border-box; 
}

.content{
    width: calc(100% - 210px);
    text-align: left;
}
.success_message{

    font-size: 1.2rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    text-decoration: none;

}
.link{
    text-decoration: none;
    cursor: pointer;
    color: rgb(212, 170, 135);
}
.link:visited{
    /* color: palevioletred; */
}


.submit_button_container{

    width: 100%;
    /* background-color: red; */
    display: flex;


}
.submit_button{
    background-color: #5D5D5C;
    color: white;
    border-radius: 0.5rem;
    width: 110px;
    height: 40px;
    
    outline: none;
    border: none;

    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;
    ;

    filter:  drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
.submit_button:hover{
    background-color: #555555;
}

@media only screen and (max-width: 750px) {
    .bookings_image_container{
        display: none;
    }
    .content{
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .bookings_content_container{
        top: calc( var(--navbar-height) + 30px);
        left: 30px; 
        right: 30px;
        bottom: 30px;
        padding: 30px;
    }
}
@media only screen and (max-width: 450px) {
    .bookings_content_container{
        top: calc( var(--navbar-height) + 30px);
        left: 30px; 
        right: 30px;
        bottom: 30px;
        padding: 30px;
    }
}