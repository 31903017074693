.edit{
    height: 4rem;
    width: 18rem;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 2;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    filter:  drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));


   
    gap: 2rem;
    box-sizing: border-box;
    padding: 10px;
    animation-name: slideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.edit a {
    text-decoration: none;
}


.banner_text {
    color: var(--black-color);
    font-size: 1.3rem;
    font-family: Arial, Helvetica, sans-serif;
}



.edit_button {
    background-color: var(--tertiary-color) ;
    border-radius: 0.4rem;
    color: var(--secondary-text-color);
    font-weight: 600;
    border: 1px solid var(--tertiary-color);
    width: 7.5rem;
    transition: ease-in-out 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-transform: uppercase;
    font-size: 14px;
}


.edit_button:hover {
    background-color: var(--tertiary-color-hover);
    cursor: pointer;
    transition: ease-in-out 0.2s;
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes hide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(0);
    }
    
}
