.paymentSuccess {
  display: flex;
  flex-direction: column;

  align-items: center;
  height: calc(100vh - var( --navbar-height));
  background-color: #333333;
}
.paymentSuccess_icon_container{
  display: flex;
  justify-content: center;
  align-items: center;
  
  /*  center */
  height: 75%;
  width: 100%;

}
  


.paymentSuccess_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;

  background-color: white;
  border-radius:  0.5rem;
  filter: drop-shadow(0 0 0.75rem #333333);
  padding: 2rem;
} 

.success_logo_container {
  height: 110px;
  width: 110px;
  margin-bottom: 1rem;
  
}
.success_logo {
  width: 100%;
  height: 100%;

}

.paymentSuccess_title {
  font-size: 2rem;
  font-weight: 600;
  background: #00CFCF;
  background: linear-gradient(to top right, #00CFCF 0%, #29CF3A 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.paymentSuccess_text {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 2rem;

}
.link {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 2rem;
  color: #00CFCF;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}