.biolinkFeedItem {
    background-color:#EBEBEB ;
    width: 100%;
    filter : drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25));
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}

.body{
    width: 100%;
    height: 100%;
    padding: 5px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 4fr;
}
.image_container{
    /* background-color: purple; */
    width: 100%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    text-align: left;
    
}
.header_image{
    width: 90px;
    height: 90px;
    object-fit: cover;
    background-color: yellow;
    border-radius: 50%;
    /* center div */

    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}
.heading_image{
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
    /* center div */

    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.heading_image.loaded{
    animation: fadein 0.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.content_container{
    /* background-color: green; */
    display:block;
    text-align: left;
    padding: 0.2rem

}

.username{
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    text-align: left;
}
.subheading{
    font-size: 0.8rem;
    font-weight: 400;
    
    margin-top: 0.5rem;
    text-align: left;
}
.rating{
    color: black;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 0.8rem;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    /* remove fade in transition */
    opacity: 1;
    animation: none;
    
}


.link {
    text-decoration: none;
    color: black;
}

@media  only screen and (max-width: 550px) {
    .tag{
        margin-left: 0.8rem;
        font-size: 0.9rem;
        height: 1.1rem;
        line-height: 1.1rem;
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }
}

@media only screen and (max-width: 450px) {
    .biolinkFeedItem{
        width: 100%;
    }

    .iamge_container{
        /* background-color: purple; */
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        box-sizing: border-box;
        
    }
    .content_container{
        margin-top: 1rem;
        width: 100%;
    }
    .username{
        font-size: 1rem;
    }
    .subheading{
        font-size: 0.7rem;
    }
    .rating{
        font-size: 0.7rem;
    }
    .tag{
        margin-left: 0.7rem;
        font-size: 0.8rem;
        height: 1rem;
        line-height: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}