.profile {

    /* font-family: 'Inria Serif', serif; */
    font-family: "Roboto", sans-serif;
    background-color: var(--background-color) ;
    color: var(--font-color-1);
    padding-bottom: 5rem;
    min-height: 100vh;

}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
.profile_content {
    animation: fadeIn 0.3s;
}

.profile_container_uneven {
    width: 1000px;
    padding: 2rem 0.5rem 1rem 0.5rem;

    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-template-columns: 1.8fr 1fr;
    gap: 2.5rem;
    row-gap: 2.5rem;
    box-sizing:content-box;
}
.profile_container_uneven.full{
    grid-template-columns: 1fr;
    row-gap: 0rem;
}
.profile_container {
    width: 1000px;
    padding: 1.7rem 0.5rem 2rem 0.5rem;

    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;
    row-gap: 2.5rem;
    /* animation: fadeIn 0.3s; */
}
    


/* profile data box css */
.profileDataBox {
    width:  100%;
    height: auto;
    background: var(--overlay-3);
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border: 2px solid var(--overlay-3);
    border-radius: 0.6rem;
    
    padding: 1.5rem;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    margin: 0 auto;

    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;

    display: flex;
    justify-content: center;
    flex-direction: column;
    
}
.profileDataBoxHeading {

    font-weight: 500;
    font-size: 3.25rem;
    text-align: left;
    width: 100%;
    
    overflow: hidden;
    text-overflow: ellipsis;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profileDataBoxHeading .copyLink {
    border-radius: 5px;
    outline: none;
    border: 0;
    border: 1px solid #ccc;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.profileDataBoxHeading .copyLink:hover {
    transform: scale(1.1);
}


.profileDataBoxHeading .copyLink p {
    padding: 0;
    margin: 0;
    margin-left: 5px;
    font-weight: medium;
}

.profileDataBoxHeading .edit {
    display: inline;
    font-size: 2rem;
}

.username {
    width: 100%;
    display: flex;
    font-family: 'Roboto Mono', Courier, monospace;
    font-weight: bolder;
    font-size: 20px;
}

.profileDataBoxSubheading {
    width: 100%;

    margin-top: 2.5rem;
    font-weight: 500;
    color: var(--font-color-1);
    font-size: 1.4rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: normal;
}
.profileDataBoxSubheading.edit {
    display: inline;
    padding-top: 3rem;
}

.profileDataBoxArtistType {
    border: 2px solid var(--tertiary-color);
    border-radius: 0.6rem;
    width: fit-content;
    padding: 3px 20px;
    margin-top: 1rem;
    text-transform: capitalize;
    user-select: none;
}

.profileDataBoxSocialMediaLinks {

    margin-top: 2.5rem;
    width: 100%;
    height: 60px;
    gap: 1.2rem;

    display: inline-flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
       display: none;
    }

}

.profileDataBoxSocialMediaLinks a {
    text-decoration: none;
}

.contact_me_button {
    background-color: var(--tertiary-color) ;
    border-radius: 0.4rem;
    color: var(--secondary-text-color);
    font-weight: 600;
    border: 1px solid var(--tertiary-color);
    width: 10rem;
    transition: ease-in-out 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-transform: uppercase;
    font-size: 14px;
}

.contact_me_button:hover {
    background-color: var(--tertiary-color-hover);
    cursor: pointer;
    transition: ease-in-out 0.2s;
}


/* profile image block */
.heading_image {
    height: 20rem;
    width: 20rem;
    
    /* object-fit: cover; */
    border-radius: 0.6rem;
    margin: 0 auto;
    background-color: var(--overlay-2);
    /* animation: fadeIn 0.3s; */
}


/* events box css */
.events_block {
    aspect-ratio: 1/1;
    width: 100%;
    text-decoration: none;



    border-radius: 0.6rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: var(--overlay-2);
    background-image: url("../../../public/pictures/link/events-temp.png");
    background-size: cover;
    background-position: center center; 

    font-family: 'K2D';
    text-align: center;
    font-size: 5rem;
    color: var(--white-color);
    justify-content: center;
    align-items: center;
    display: flex;
    transition: ease-in-out 0.2s;
}
.events_block:hover  {
    cursor: pointer;

    transform: scale(1.01);
    transition: ease-in-out 0.2s;
    
}
/* bookings box css */
.bookings_block {
    aspect-ratio: 1/1;
    width: 100%;

    border-radius: 0.6rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: var(--overlay-2);;
    background-image: url("../../../public/pictures/link/bookings-temp.png");
    background-size: cover;
    background-position: center center; 

    font-family: 'K2D';
    text-align: center;
    font-size: 5rem;
    color: var(--white-color);
    justify-content: center;
    align-items: center;
    display: flex;
    transition: ease-in-out 0.2s;
}

.bookings_block:hover  {
    cursor: pointer;

    transform: scale(1.01);
    transition: ease-in-out 0.2s;
    
}

/* mailing list block css */
.mailing_list_block {
    aspect-ratio: 1/1;
    width: 100%;

    border-radius: 0.6rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: var(--overlay-2);
}
.mailing_list_block_header {
    font-family: 'K2D';
    text-align: center;
    padding-top: 20%;
    padding-bottom: 10%;
    font-size: 2rem;
    font-weight: 600;
    color: var(--font-color-1);
}
.mailing_list_block_content{
    padding: 2rem;
    
    justify-content: center;
}
.mailing_list_item {
    display: flex;
    justify-content: center;
    width: 100%;
}
.mailing_list_input {
    width: 70%;
    height: 60px;
    border-radius: 0.1rem;
    border: none;
    padding-left: 1rem;
    font-size: 1.3rem;
    line-height: 60px;
    font-weight: 500;
    color: black;
    transition: ease-in-out 0.2s;
}
.mailing_list_button{
    height: 60px;
    background-color: var(--tertiary-color) ;
    border-radius: 0.1rem;
    color: var(--tertiary-text-color);
    border: none;
    line-height: 60px;
    font-size: 1.1rem;
    width: 30%;
    transition: ease-in-out 0.2s;
    cursor: pointer;
}
.mailing_list_button:hover {
    background-color: var(--tertiary-color-hover);
    transition: ease-in-out 0.2s;
}
.mailing_list_subscribed{
    font-family: 'K2D';
    text-align: center;
    padding-bottom: 10%;
    font-size: 2rem;
    font-weight: 600;
    color: var(--font-color-1);
}

/* invest css */
.invest_block {
    aspect-ratio: 1/1;
    width: 100%;

    border-radius: 0.6rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-color: var(--overlay-2);;
    background-image: url("../../../public/pictures/link/invest-temp.png");
    background-size: cover;
    background-position: center center; 

    font-family: 'K2D';
    text-align: center;
    font-size: 5rem;
    color: var(--white-color);
    justify-content: center;
    align-items: center;
    display: flex;
    transition: ease-in-out 0.2s;
}
.invest_block:hover  {
    cursor: pointer;

    transform: scale(1.01);
    transition: ease-in-out 0.2s;
    
}

/* links block css*/
.links_block {
    aspect-ratio: 1/1;
    width: 100%;
    background-color: var(--overlay-3);
    border-radius: 0.6rem;
    border: 2px solid var(--overlay-3);
    display: block;

}
.link:last-child {
    margin-bottom: 0;

}
.links_block_inner {
    padding: 1rem;
}

.link_item {
    height: 4rem;
    width:100%;
    border-radius: 0.6rem;
    text-align: left;
    padding-left: 2rem;
    background-color: var(--overlay-5);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    color: var(--font-color-1);
    font-size: 1.2rem;
    line-height: 6.1rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    transition: ease-in-out 0.2s;
}

.link_icon {
    padding-right: 2rem;
    color: var(--primary-color);
}

.link_item:hover {
    background-color: var(--overlay-9);
    cursor: pointer;
}

.link_item.hidden {
    display: none;
}
.link_item.edit {
    margin-bottom: 1rem;
    position: relative;
}


.link {
    text-decoration: none;
    color: var(--overlay-12);
    margin-bottom: 1.5rem;
    display: flex;

}
.link:visited {
    text-decoration: none;
}



/* spotify links block css*/
.spotify_links_block {
    aspect-ratio: 1/1;
    width: 100%;
  
    background-color: var(--overlay-3);
    border-radius: 0.6rem;
    overflow: hidden;
}
.spotify_links_block_inner {
    
    padding: 0rem;

    border-radius: 0.6rem;
    display: block;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    border-radius: 0.8rem;
    padding-bottom: 0rem;
}

.spotify_embed {
    width: 100%;
    height: 9.48rem;
    margin-bottom: 0.55rem;
    background-color:  var(--overlay-2);
    border-radius: 0.8rem;
    overflow-y: hidden;

}
.spotify_embed:last-child {
    margin-bottom: 0;
}


/* youtube links block css*/
.youtube_links_block {

    padding: 0rem;

    aspect-ratio: 1/1;
    border-radius: 0.6rem;

    background-color: var(--overlay-3);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    border-radius: 0.8rem;
    padding: 0.4rem;

}
.youtube_title {
    padding-top: 2.5rem;
    width: 250px;
    text-align: center;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
   
    margin-bottom: 1rem;
    color: var(--font-color-1);
    font-size: 1.3rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    font-family: "K2D";
}
.underline {
    width: auto;
    height: 0.2rem;
    background-color: var(--overlay-0);
    margin-bottom: 1.5rem;

}
.embed_container{   
    width: 100%;


}
.youtube_embed {
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;


}

/* soundcloud links block css*/
.soundcloud_links_block {

    padding: 0rem;
    aspect-ratio: 1/1;
    width: 100%;
    overflow: hidden;
    background-color: var(--overlay-3);
    border-radius: 0.6rem;
    display: block;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    border-radius: 0.8rem;
    padding-bottom: 0rem;
}
.soundcloud_embed {
    width: 100%;
    height: 9.47rem;
    margin-bottom: 0.4rem;
    border-radius: 0.8rem;
    padding: 0.05rem;
    outline: rgb(36, 36, 36) solid 0.6rem;
    outline-offset: -0.6rem;
    background-color: rgb(36, 36, 36);

}
.soundcloud_embed:last-child {
    margin-bottom: 0;
}

.name_container {
    margin-top: 1rem;
    color: var(--font-color-1);
    font-size: 1.5rem;
    font-weight: 700;
}

.subheading_container {
    margin-top: 1rem;
    color: var(--font-color-1);
    font-size: 1rem;
    font-weight: 700;
}

.social_media_links_container{
    width: auto;
    margin: 0 auto;
    
    display: inline-flex;
    padding-top: 1rem;
    gap: 1.2rem;

    white-space: nowrap;
   
    
    
}
.social_media_link_item {
    height: 44px;
    width:44px;
    background-color: var(--white-color);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 5px;
    border-radius: 50%;
    
}
.social_media_link_image {
    height: 100%;
    width: 100%;
    filter: invert(3%) sepia(10%) saturate(477%) hue-rotate(314deg) brightness(112%) contrast(76%);
    border-radius: 50%;
}



.link_image{

    position: absolute;
    left: 10px;
    
    top: 10px;
    bottom: 10px;

}
.link_image_item {
    height: 100%;
}




@media only screen and (max-width: 1100px) {
    .profile_container_uneven {
        width: 700px;
        padding: 2rem 0rem 0.5rem 0rem;
        gap: 0rem;
        row-gap: 1rem;
        grid-template-columns:  1fr;
   
    }
    .heading_image {
        order: 1;
    }
    .profileDataBox {
        order: 2;
    }
    .profile_container {
        width: 700px;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        gap: 1rem;
        row-gap: 1rem;
    }
    .spotify_embed {
        width: 100%;
        height: 5rem;
    }
    .youtube_title {
        font-size: 1.1rem;
    }
    .soundcloud_embed{
        height: 6.5rem;
    }
    .mailing_list_block_header {
        font-size: 1.5rem;
    }
    .mailing_list_block_content{
        padding: 2rem 1rem;
    }
    .mailing_list_input {
        height: 45px;
        font-size: 1.2rem;
        line-height: 45px;
    }
    .mailing_list_button{
        height: 45px;
        line-height: 45px;
        font-size: 1.0rem;
    }
    .mailing_list_subscribed{
        font-size: 1.4rem;
    }
    .link_item {
        height: 4.4rem;
        line-height: 4.4rem;
        font-size:  1.2rem;
    }
    .link {
        text-decoration: none;

        margin-bottom: 0.8rem;
        display: flex;
    
    }

}

@media only screen and (max-width: 850px) {
    .profile_container_uneven {
        width: 500px;
        padding: 2rem 0rem 0.5rem 0rem;
        gap: 0rem;
        row-gap: 1rem;
        grid-template-columns:  1fr;
   
    }
    .link_item {
        height: 6.1rem;
        line-height: 6.1rem;
    }
    .profile_container {
        width: 500px;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        gap: 1rem;
        row-gap: 1rem;
        grid-template-columns:  1fr;
    }

    .profileDataBoxHeading {  
        font-size: 2.5rem;
    }
    .profileDataBoxSubheading {
        font-size: 1.4rem;
        padding-bottom: 1rem;
    }


    .links_block{
        height: auto;
        aspect-ratio: auto;
    }
    .spotify_links_block{
        height: auto;
        aspect-ratio: auto;
    }
    .spotify_embed {
        width: 100%;
        height: 10rem;
    }
    .youtube_title {
        font-size: 1.1rem;
    }
    .soundcloud_links_block{
        height: auto;
        aspect-ratio: auto;
    }
    .soundcloud_embed{
        height: 10rem;
    }
    .mailing_list_block_header {
        font-size: 2rem;
        padding:20% 0rem 10% 0rem;
    }
    .mailing_list_block_content{
        padding: 2rem ;
    }
    .mailing_list_input {
        width: 70%;
        height: 60px;
        font-size: 1.3rem;
        line-height: 60px;
    }
    .mailing_list_button{
        width: 30%;
        height: 60px;
        line-height: 60px;
        font-size: 1.1rem;
    }
    .mailing_list_subscribed{
        font-size: 2rem;
    }

}

@media only screen and (max-width: 600px) {
    .profile_container_uneven {
        width: 350px;  
        overflow: hidden;
    }

    .contact_me_button {
        height: 60px;
        width: 7rem;
    }

    .profile_container {
        width: 350px;
    }
    .profileDataBox{
        padding: 1.2rem;
    }
    .profileDataBoxHeading {  
        font-size: 2.5rem;
    }
    .profileDataBoxSubheading {
        font-size: 1.4rem;
        padding-bottom: 1rem;
    }
    .link_item {
        height: 4.7rem;
        line-height: 4.7rem;
        font-size:  1.1rem;
    }
    .link{
        margin-bottom: 0.7rem;
    }
    .mailing_list_block_header {
        font-size: 1.5rem;
    }
    .mailing_list_input {
        height: 45px;
        font-size: 1.2rem;
        line-height: 45px;
    }
    .mailing_list_button{
        height: 45px;
        line-height: 45px;
        font-size: 1.0rem;
    }
    .mailing_list_subscribed{
        font-size: 1.4rem;
    }

}
@media only screen and (max-width: 450px) {
    .profile_container_uneven {
        width: 250px;  

    }
    .profile_container {
        width: 250px;
    }

    .contact_me_button {
        width: 6rem;
        font-size: 12px;
        height: 35px;
    }

    .social_media_link_item {
        height: 30px;
        width: 30px;
    }
 
    .profileDataBox{
        padding: 0.6rem;
    }
    .profileDataBoxHeading {  
        font-size: 1.5rem;
    }
    .profileDataBoxSubheading {
        font-size: 1.2rem;
    }

    .heading_image {
        height: 15rem;
        width: 15rem;
    }

    .link_item {
        height: 3.2rem;
        line-height: 3.2rem;
        font-size:  1.0rem;
    }
    .link{
        margin-bottom: 0.5rem;
    }

    .youtube_title {
        width: 190px;
        font-size: 1rem;
    }
    .bookings_block{
        font-size: 3rem;
    }
    .events_block{
        font-size: 3rem;
    }
    .invest_block{
        font-size: 3rem;
    }
    .mailing_list_block_header {
        font-size: 1.2rem;
        padding:3rem 1rem 0rem 1rem;
    }
    .mailing_list_block_content{
        padding: 2rem 1rem;
    }
    .mailing_list_input {
        width: 60%;
        height: 40px;
        font-size: 1rem;
        line-height: 40px;
    }
    .mailing_list_button{
        width: 40%;
        height: 40px;
        line-height: 40px;
        font-size: 1.0rem;
    }
    .mailing_list_subscribed{
        font-size: 1.2rem;
    }

}