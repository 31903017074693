
.terms{
    padding: 4rem 10%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    background-color: rgb(240, 240, 240);
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.content {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
}

.title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    padding: 2rem 0rem 1rem 0;
    text-align: left;
}

.text {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: left;
}

.text p {
    text-align: left;
}

.list {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: left;
    list-style: disc;
    padding-left: 2rem;
}
.list li {
    margin-bottom: 0.5rem;
    text-align: left;
}
