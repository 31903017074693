.save{
    height: 4rem;
    width: 20rem;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 2;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;




   
    gap: 2rem;
    box-sizing: border-box;
    padding: 10px;
}
.save.changed {

    transform: translateX(0);
    animation-name: slideIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.save.no_changes {

    animation-name: slideOut;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.save.saving {

}

.save_text {
    color: var(--black-color);
    font-size: 1.3rem;
    font-family: Arial, Helvetica, sans-serif;
}

.save_button {
    height: 2.5rem;
    width: 7rem;
    border-radius: 5px;
    border: none;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 1.3rem;
    cursor: pointer;
    justify-self: flex-end;
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes hide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(0);
    }
    
}
