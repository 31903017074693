.bookingsFeedItem {
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding: 10px;
    box-sizing: border-box;
    position: relative;
}
.container{
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 5px;

}

.image_contaienr{
    width: 100%;
    height: 100%;
    padding: 1rem;
    width: 200px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    text-align: left;
    
}
.heading_image{
    width: 120px;
    height: 120px;
    object-fit: cover;
    
    border-radius: 50%;
    /* center div */

    position: relative;

    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

.title{
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    font-family: sans-serif;
    color: rgb(27, 27, 27);
    margin-bottom: 0.5rem;
    text-align: left;
}
.description{
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    font-family: sans-serif;
    color: rgb(27, 27, 27);
    margin-bottom: 0.5rem;
    text-align: left;
    
}
.price {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    font-family: sans-serif;
    color: rgb(27, 27, 27);
    margin-bottom: 0.5rem;
    text-align: left;
}

.date {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    font-family: sans-serif;
    color: rgb(27, 27, 27);
    margin-bottom: 0.5rem;
    text-align: left;
}
.notes {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    font-family: sans-serif;
    color: rgb(27, 27, 27);
    margin-bottom: 0.5rem;
    text-align: left;
    overflow: hidden; /* Keeps the content within the boundaries */
    word-wrap: break-word; /* Allows words to break and wrap onto the next line */
    white-space: normal; /* Allows content to wrap to the next line */
    max-width: 80%; /* Adjusts the width of the container */
    width: 600px
}
.status {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    font-family: sans-serif;
    color: rgb(27, 27, 27);
    margin-bottom: 0.5rem;
    text-align: left;
}
.rating_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.5rem;
}
.rating{
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    font-family: sans-serif;
    color: rgb(27, 27, 27);
    text-align: left;
    display: inline-flex;
    align-items: center;
}
.rating_item{
    margin-top: 0px;
    margin-left: 4px;
}

/* action buttons  */
.action_buttons_container{
    /* bottom rightcorner */
    position: absolute;
    bottom: 5px;
    right: 15px;

}
/* bookingsFeedItem.module.css */
.action_button {
    padding: 8px 16px; /* Adjust padding to suitable values */
    background-color: #007bff; /* Example color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block; /* This makes the button size to content */
    text-align: center;
}

/* For more specific adjustments, you can reduce the padding or set min-width if needed */
.action_button.refund {
    padding: 8px 12px; /* Slightly smaller padding */
    min-width: 100px; /* Minimum width to handle very small content gracefully */
}

.action_button.accept{
    background-color: rgb(0, 255, 0);
    color: rgb(255, 255, 255); 
    margin-right: 10px;
}
.action_button.accept:hover{
    background-color: rgb(0, 200, 0);
}

.action_button.decline{
    background-color: rgb(255, 0, 0);
    color: rgb(255, 255, 255);

}
.action_button.decline:hover{
    background-color: rgb(200, 0, 0);
}

.action_button.counter{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: 1px solid rgb(0, 0, 0);
    margin-right: 10px;
}
.action_button.counter:hover{
    background-color: rgb(200, 200, 200);
}

.action_button.cancel {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    border: 1px solid rgb(0, 0, 0);
}
.action_button.cancel:hover {
    background-color: rgb(200, 200, 200);
}

.action_button.pay{
    background-color: rgb(0, 0, 255);
    color: rgb(255, 255, 255);
}
.action_button.pay:hover{
    background-color: rgb(0, 0, 200);
}
.action_button.create{
    background-color: rgb(0, 0, 255);
    color: rgb(255, 255, 255);
}
.action_button.create:hover{
    background-color: rgb(0, 0, 200);
}

.mailto_link {
    text-decoration: none;
}

.action_button.refund{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}
.action_button.refund:hover{
    background-color: rgb(65, 65, 65);
}


@media only screen and (max-width: 600px) {
    /*  render action buttons at the bottom center instead */
    .action_buttons_container{
        position: unset;
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
    .title{
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 0.2rem;
        
    }
    .description{
        font-size: 16px;
        line-height:  20px;
        margin-bottom: 0.2rem;
    }
    .price {
        font-size: 20px;
        line-height:  25px;
        margin-bottom: 0.2rem;
    }
    .date {
        font-size: 16px;
        line-height:  20px;
        margin-bottom: 0.2rem;
    }
    .notes {
        font-size: 16px;
        line-height:  20px;
        margin-bottom: 0.2rem;
    }
    .status {
        font-size: 16px;
        line-height:  20px;
        margin-bottom: 0.2rem;
    }
    .rating{
        font-size: 16px;
        line-height:  20px;
        margin-bottom: 0.2rem;
    }
    .heading_image{
        width: 100px;
        height: 100px;

    }
    .container{
        padding: 0;
    }

}

@media only screen and (max-width: 600px) {
    .container{
        grid-template-columns: 1fr;
        padding: 10px;
    }

}

