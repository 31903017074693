.get_image_file {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: #292727af;

    padding-top: 7%;
    padding-bottom: 15%;
    padding-left: 20%;
    padding-right: 20%;
    animation: fadeIn 0.5s;

    }
    
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

.close_button_container {
    display: flex;
    justify-content: flex-end;
}

.close_button {
    width: 20px;
    cursor: pointer;
}

.drop_container {
    top: 40px;
    position: relative;
    min-height: 300px;
    max-height: 70vh;
    border-radius: 15px;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    overflow-y: auto;
    animation: slideIn 0.5s;
    padding: 20px;
}

@keyframes slideIn {
    from {
        transform: translateY(+50%);
    }
    to {
        transform: translateY(0%);
    }
}

.drop_zone {

    min-height: 300px;
    padding: 15px;
    margin-bottom: 20px;
    border:dashed 2px  #a1a1a1;
    margin-top: 10px;
}
.drop_zone:hover {
    cursor: pointer;
    background-color: #e2e9fd;
}

.drop_text {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #000000;
    font-family: Arial, Helvetica, sans-serif;
    
    padding-top: 20px;
    padding-bottom: 20px;
    
}
.drop_text:hover {
    cursor: pointer;
}

.upload_button_container {
    text-align: center;
}

.upload_button {
    background-color: var(--primary-color);
    border-radius: 0.2rem;
    color: #ffffff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    border: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-weight: 600;
    color: var(--secondary-text-color);
    background-color: var(--tertiary-color);
    text-transform: uppercase;
    font-size: 1.1rem;
}

.upload_button_disabled {
    background-color: var(--primary-color);
    border-radius: 0.2rem;
    color: #ffffff;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    border: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-weight: 600;
    color: var(--secondary-text-color);
    background-color: var(--tertiary-color);
    text-transform: uppercase;
    font-size: 1.1rem;
    cursor: not-allowed;
    opacity: 0.5;
}

.upload_button:hover {
    cursor: pointer;
    background-color: var(--primary-color-hover);
}

@media only screen and (max-width: 600px) {

    .drop_text {
    
    
        font-size: 1.2rem;
    }
    .upload_button {
        font-size: 1.2rem;
    }
}

.images {
    margin-top: 20px;
}

.drag_icon {
    margin-top: 25px;
    color: var(--primary-color)
}

.remove_image_container {
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
    height: 0;
    width: 100%;
}

.remove_image_button {
    width: 20px;
    height: 20px;
    padding: 5px;
    background-color: var(--primary-color);
    color: white;
    z-index: 10;
}