.changePassword{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: auto;
    animation: fadeIn 0.5s;

}

@keyframes fadeIn {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}
    

.changePassword_container{
    position: absolute;
    top: 10%;
    right: 23%;
    left: 23%;
    min-height: 400px;
    background-color: white;
    border-radius: 10px;
    z-index: 4;
    animation: slideIn 0.5s;
    padding: 20px;
    overflow: hidden;
    
}

@keyframes slideIn {
    from {
        transform: translateY(+50%);
    }
    to {
        transform: translateY(0%);
    }
}

@media only screen and (max-width: 700px) {
    .changePassword_container{
        top: 5%;
        right: 5%;
        left: 5%;
    }
}

.changePassword_container_title{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.changePassword_container_form_input{
    text-align: left;
}

.changePassword_container_form_button{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.button{
    background-color:  rgb(36, 36, 36) ;
    color: white;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 0.5rem 0.75rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: all 0.2s ease-in-out 0s;
    text-decoration: none;
    width: 100%;
    font-weight: 500;
}