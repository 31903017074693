.landing {
  width: 100%;

  font-family: "M PLUS 1p", "Open Sans", sans-serif;
  background-color: rgb(26, 26, 26);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.logo_container {
  padding-top: 2rem;
}
.logo {
  height: 110px;
  margin-bottom: 5%;
}

.content_container {
  background-image: url("/public/pictures/landing/background2.jpg");

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  width: 100%;
  height: calc(100vh - var(--navbar-height));

  animation: fadeIn 0.6s;
}

.landing_title_container {
  max-width: 800px;
  margin: 0 auto;
}
.landing_title {
  font-weight: 700;
  font-size: 76px;
  line-height: 105.1px;
  font-family: sans-serif;
  color: white;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* Default style */
.event_text {
  color: white;
  font-size: 30px;
  margin-bottom: -10px;
}

/* Smaller screens: max-width of 768px is a common breakpoint for tablets */
@media screen and (max-width: 768px) {
  .event-text {
    font-size: 20px; /* Smaller font size for smaller screens */
  }
}

.email_input {
  width: 250px;
  height: 45px;
  line-height: 45px;
  text-align: left;
  border-radius: 1px 0px 0px 1px;
  background-color: #ffffff;
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: rgb(27, 27, 27);
  transition: 0.3s;
  margin-top: 15px;
  padding-left: 20px;
  outline: none;
  border: none;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.email_submit_button {
  width: 110px;
  height: 46px;
  line-height: 45px;
  border-radius: 0px 1px 1px 0px;
  background-color: #111111;
  border: none;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
  margin-top: 15px;
  margin-left: 0px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.email_submit_button:hover {
  background-color: #3b3b3b;
}

.subscribe_success {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-top: 10px;
  margin-left: 10px;
  outline: none;
}

.display_form {
  display: block;
}
.hide_form {
  display: none;
}

.description_container {
  height: 21rem;
  width: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}
.description_text {
  color: white;
  text-align: center;
  font-family: K2D;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.screenshot_container {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  min-height: 700px;
}
.biolink_screenshot_description_container {
  width: 500px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  margin: 0 auto;
}
.biolink_screenshot_description_text {
  color: #fff;
  font-family: K2D;
  font-size: 2.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.biolink_screenshot {
  align-self: flex-start;
  height: 675px;
  border-radius: 1.3rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.biolink_screenshot_item {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 1.3rem;
}

.buffer_container {
  width: 100%;
  height: 5rem;
  background: url("/public/pictures/landing/buffer_image.png"),
    lightgray 50% / cover no-repeat;
}
.buffer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buffer_text {
  color: #fff;
  font-family: K2D;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mini_biolink_container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background: url("/public/pictures/landing/background.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.mini_biolink {
  height: 540px;
  border-radius: 1.5rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.mini_biolink_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 1.5rem;
}

.mini_biolink_description {
  width: 37rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6rem;
  margin: 2rem auto 0rem auto;
}
.mini_biolink_description_text {
  color: #fff;
  font-family: K2D;
  font-size: 2.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

.landing_footer {
  width: 100%;
  height: 7rem;
  background-color: #111111;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 3rem;
}
.address {
  padding-top: 2rem;
  color: white;
  font-size: 22px;
  width: 100%;
}

@media only screen and (max-width: 1150px) {
  .screenshot_container {
    display: block;
  }
  .mini_biolink_container {
    display: block;
  }
  .mini_biolink_description {
    padding: 0;
  }
  .mini_biolink_description_text {
    text-align: center;
  }
}

@media only screen and (max-width: 850px) {
  .landing_title {
    font-size: 70px;
    line-height: 90px;
  }
}

@media only screen and (max-width: 700px) {
  .landing_title {
    font-size: 55px;
    line-height: 70px;
  }
  .sign_up_button {
    height: 35px;
    line-height: 35px;
  }
  .description_text {
    font-size: 2rem;
  }
  .biolink_screenshot_description_text {
    font-size: 1.8rem;
  }
  .biolink_screenshot {
    height: 600px;
  }
  .screenshot_container {
    min-height: 650px;
  }
  .mini_biolink_description_text {
    font-size: 1.8rem;
  }
  .mini_biolink {
    height: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .content_container {
    top: 40%;
  }

  .landing_title {
    font-size: 50px;
    line-height: 70px;
  }

  .description_text {
    font-size: 1.8rem;
  }

  .biolink_screenshot_description_container {
    width: 100%;
    box-sizing: border-box;
  }
  .biolink_screenshot_description_text {
    font-size: 1.6rem;
  }
  .biolink_screenshot {
    width: 100%;
    box-sizing: border-box;
    padding: 0rem 2rem 0rem 2rem;
  }
  .mini_biolink_description {
    width: 100%;
    margin: 0rem 0rem 0rem 0rem;
    padding: 1rem 1rem 0rem 1rem;
    box-sizing: border-box;
  }
  .mini_biolink_description_text {
    font-size: 1.6rem;
  }
  .mini_biolink {
    height: 450px;
  }
}
@media only screen and (max-width: 500px) {
  .landing_title {
    font-size: 40px;
    line-height: 65px;
  }
  .email_input {
    width: 180px;
    font-size: 16px;
    padding-left: 20px;
  }
  .email_submit_button {
    font-size: 16px;
  }
  .email_input {
    font-size: 15px;
    padding-left: 15px;
  }
}
@media only screen and (max-width: 400px) {
  .landing_title {
    font-size: 35px;
    line-height: 60px;
  }
  .email_input {
    width: 180px;
    font-size: 14px;
    padding-left: 15px;
  }
  .logo {
    scale: 0.8;
  }
  .address {
    font-size: 18px;
  }
  .biolink_screenshot_description_container {
    width: 100%;
    box-sizing: border-box;
    padding: 0rem 1rem 0rem 1rem;
  }
  .description_container {
    width: 100%;
    box-sizing: border-box;
    padding: 0rem 1rem 0rem 1rem;
  }
}
