:root {
  font-family: "Roboto", sans-serif;
  color: var(--black-color);
}
.module_form {
  padding-top: 15px;
  width: 500px;
  margin: 0 auto;
  display: hidden;
  font-family: "Roboto", sans-serif;
  color: var(--black-color);
}

.module_form.left {
  animation: slideFromLeft 0.5s ease-in-out;
}
.module_form.right {
  animation: slideFromLeft 0.5s ease-in-out;
}

@keyframes slideFromLeft {
  0% {
    transform: translatex(-10%);
    opacity: 0.5;
  }
  100% {
    transform: translatex(0);
    opacity: 1;
  }
}

/* form styling */
.module_header {
  font-size: 1.5rem;
  font-weight: 500;
  padding-bottom: 1.5rem;
  color: var(--black-color);
}

.module_header h3 {
  margin-bottom: 0;
}

.module_header a {
  font-weight: 500;
  color: var(--primary-color);
  text-decoration: none;
  transition: ease-in-out 0.2s;
}

.module_header a:hover {
  color: var(--primary-color-hover);
}

.module_form_input label {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-align: left;
  line-height: 2.5rem;
}

.module_form_input {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

input {
  width: 100%;
  height: 2.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding-left: 0.5rem;

  text-align: left;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border: 1px solid #aaa;
}
.module_form_input {
}

.module_form_textarea {
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  resize: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: left;
  box-sizing: border-box;
  font-size: 1rem;
  /* scroll style */
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) transparent;
}

.module_form_buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.manage_stripe_button {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
}
.module_form_button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.module_form_button.delete {
  transition: ease-in-out 0.2s;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.4rem;
  border: 1px solid var(--overlay-10);
}

.module_form_button.delete:hover {
  background: var(--overlay-8);
}

.module_form_button.cancel {
  background: var(--cancel-color);
  color: var(--cancel-text-color);
  transition: ease-in-out 0.2s;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.4rem;
}

.module_form_button.cancel:hover {
  background: var(--cancel-color-hover);
  color: var(--cancel-text-color);
}

.module_form_button.save {
  background-color: var(--primary-color);
  color: var(--white-color);
  transition: ease-in-out 0.2s;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.4rem;
}

.module_form_button.save:hover {
  background-color: var(--primary-color-hover);
  color: var(--white-color);
}

/* mailchimp button */
.mailchimp_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(236, 236, 236);
  cursor: pointer;
  margin: 0 auto;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
  border-radius: 1rem;
  width: 12rem;
  height: 5rem;
}
.mailchimp_icon_container:hover {
  background-color: rgb(226, 226, 226);
}
.form_loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader {
  animation: fadeIn 1s ease-in-out infinite alternate;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* mailing list */
.form_group {
  display: grid;
  grid-template-columns: 1fr;
}
.select_label {
  font-size: 1.2rem;
  font-weight: 500;
  margin-right: 1rem;
  text-align: center;
  line-height: 2.5rem;
}
.select {
  width: 60%;
  height: 3.5rem;
  line-height: 3.5rem;
  border-radius: 0.6rem;
  border: none;
  background-color: #f0f0f0;
  margin-top: 0.5rem;

  margin-bottom: 5.4rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  color: black;
  justify-self: center;
}
.select:invalid {
  text-align: center;
  color: #b6b6b6;
}
.option {
  color: black;
}

@media only screen and (max-width: 1150px) {
  .module_form {
    width: 400px;
  }
  .module_form_input {
    grid-template-columns: 1fr 3fr;
  }
}

@media only screen and (max-width: 550px) {
  .module_form {
    padding-top: 25px;
    width: 90%;
  }
  .module_form_input {
    grid-template-columns: 1fr;
  }
  #label {
    font-size: 1rem;
    text-align: center;
  }
  .module_form_buttons {
  }
  .module_header {
    text-align: center;
    font-size: 1.2rem;
    padding-bottom: 0rem;
  }
}

@media only screen and (max-width: 400px) {
  .module_form_button {
    margin-left: 10px;
  }
  .module_form {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .responsive_text {
    font-size: 12px;
  }
}
