

.edit {
    width: 1010px;
    margin: 0 auto;
    font-family: "Roboto", sans-serif;


}

/* theme select */  
.toggle_theme_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
}
.toggle_theme_wrapper span {
    font-size: 28px;
    margin: 0 4px;
}

.toggle_theme {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
}

.toggle_theme input {
    display: none;
}

.slider {
    background-color: #ccc;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
}
.slider:before {
    background-color: #fff;
    bottom: 4px;
    content: "";
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
}
input:checked + .slider:before {
    transform: translateX(26px);
}
input:checked + .slider {
    background-color: rgb(34, 34, 34);
}
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}

/* end theme select */

/* edit profile data box */ 
.profileDataBoxForm {
    display: grid;
    grid-template-columns: 1fr;
}

.profileDataBoxForm label {
    font-size: 20px;
}

.profileDataBoxHeading {
    text-align: left !important;
}

.form {
    outline: none;
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    font-family: "Roboto", sans-serif;
    background-color: var(--overlay-5);
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}





.heading_image_container {
    position: relative;

    height: 100%;
    width: 100%;
    margin: 0 auto;
}

.edit_icon_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #919191;
    border-radius: 5px;
    padding: 4rem;
    opacity: 0.1;
    transition: .5s ease-in-out;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}
.edit_icon_overlay_image{

    height: 100%;
    width: 100%;
}

.edit_icon_overlay:hover {
    cursor: pointer;
    opacity: 0.5;
    
    border: solid 4px #0044ff;
}

.edit_icon_header {
    margin-left: 1rem;
    width: 20px;
    height: 20px;
    filter: invert(60%) sepia(3%) saturate(25%) hue-rotate(335deg) brightness(96%) contrast(88%);
}
.edit_icon_header:hover {
    cursor: pointer;
}

.edit_text_text {
    /* background-color: rgb(97, 97, 97); */
    font-family: "Roboto", sans-serif;
    background-color: var(--overlay-5);
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    min-width: 100%;
    width: 100%;
    min-height: 30px;

    text-align: left;
    overflow-wrap: break-word;
    overflow: hidden;
    cursor: text;
    
}



.edit_icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;   
    filter: invert(60%) sepia(3%) saturate(25%) hue-rotate(335deg) brightness(96%) contrast(88%);
    color: var(--primary-color);
}   






.dropdown_icon_container {
    position: relative;
   
    width: 100%;
    height: 100%;
}
.dropdown_icon_container:hover {
    cursor: pointer;
}

.dropdown_icon {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;


    top: 1rem;
    right: 1.3rem;

    filter: invert(60%) sepia(3%) saturate(25%) hue-rotate(335deg) brightness(96%) contrast(88%);
    
    color: var(--font-color-1);

}  



.add_button {
    margin-left: 0.5rem;
    line-height:3.5rem;
    font-size: 1.1rem;    
    color: #ffffff;
    border-radius: 5px;
    color: var(--secondary-text-color);
    background-color: var(--tertiary-color);
    text-transform: uppercase;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: fit-content;
    height: 3rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
}

.add_button:hover {
    transition: all 0.2s ease-in-out;
    background-color: var(--tertiary-color-hover);
    cursor: pointer;
}

.add_icon {
    margin-right: 10px;
}

.edit_container {
    width: calc(100% - 3rem);
    background-color: var(--overlay-9);
    border-radius: 15px;
    margin-bottom: 2.2rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    

}

.form_container {
    display: grid;
    grid-template-columns: 1fr 7fr;
    row-gap: 10px;
}

.edit_label {
    text-align: left;

    font-size: 1rem;
    font-weight: 600;
    min-height:50px;
    line-height: 50px;
}

.edit_link_container {
    width: 100%;

    
}
.edit_link_container input {

    font-weight: 500;
    line-height: 50px;
    display: inline-flex;
    font-size:  1rem; 

}

.link_text_input {
    width: calc(100% - 13px - 13px) ;
    padding-left: 13px;
    padding-right: 13px;
    height: 100%;
    border: none;
    outline: none;
    background-color: var(--background-color);
    border: 1px solid var(--overlay-0);
    color: var(--font-color-1);

    text-align: left;
    border-radius: 1px ;
    
}
.link_url_input {
    width: calc(100% - 13px - 13px) ;
    padding-left: 13px;
    padding-right: 13px;
    height: 100%;
    border: none;
    outline: none;
    background-color:  var(--background-color);
    border: 1px solid var(--overlay-0);

    text-align: left;
    border-radius: 1px;
    color: var(--font-color-1)

}



.divider_line {
    width: 100%;
    height: 1px;
    background-color: var(--font-color-1);
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.divider_text{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--font-color-1);
}

.link_item_open{
    display: block;
    transition: ease-in-out 5s;
    max-height: 100%;

    overflow: hidden;
    transition: transform 0.5s ease-out;
    height: auto;
    transform: scaleY(1);
    transform-origin: right;

}
.link_item_open.hidden{
    transform-origin: bottom;
    transform: scaleY(0);
    animation-name: slide-closed;
    animation-duration: 0.5s;
    max-height: 0px;


}



@keyframes slide-closed {

  
    from {
        max-height:390px;

        -webkit-transition: height 0.5s linear;
           -moz-transition: height 0.5s linear;
            -ms-transition: height 0.5s linear;
             -o-transition: height 0.5s linear;
                transition: height 0.5s linear;
        
    }
    to {
        max-height:0rem;


        -webkit-transition: height 0.5s linear;
           -moz-transition: height 0.5s linear;
            -ms-transition: height 0.5s linear;
             -o-transition: height 0.5s linear;
                transition: height 0.5s linear;

    }

}


.delete_button_container {
    line-height: 3rem;
    font-weight: 600;
    font-size: 1rem;
    height: 30px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 150px;
    
    margin: 2rem 0 0 auto

}
.delete_button {
    transition: ease-in-out 0.2s;
    background-color: var(--secondary-color);
    color:var(--secondary-text-color);
    cursor: pointer;
    border-radius: 10px;
}
.delete_button:hover {
    background-color: var(--secondary-color-hover);
}

.buttons_container {
    margin-top: 34px;
    display:grid;
    grid-template-columns: 1fr 1fr;
    line-height: 60px;
    font-weight: 600;
    font-size: 20px;
    height: 60px;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 100%;
    margin-bottom: 3rem;
}
.save_button {
    background-color: var(--primary-color);
    color: var(--secondary-text-color);
    border-radius: 10px 0px 0px 10px;
    text-align: center;
    transition: ease-in-out 0.2s;
}
.save_button:hover {
    transition: ease-in-out 0.2s;
    background-color: var(--primary-color-hover);
    cursor: pointer;

}
.cancel_button {
    background-color: var(--cancel-color);
    color: var(--cancel-text-color);
    border-radius: 0px 10px 10px 0px;
    transition: ease-in-out 0.2s;

}
.cancel_button:hover {
    background-color: var(--cancel-color-hover);
    cursor: pointer;
    transition: ease-in-out 0.2s;

}

@media only screen and (max-width: 1100px) {
    .edit {
        width:700px;
        margin: 0 auto;
    }
    .heading_image_container {
        position: relative;
    
        width: 100%;
        height: 100%;
        /* object-fit: cover; */
        margin: 0 auto;
    }
    
}

@media only screen and (max-width: 850px) {
    .edit {
        width:500px;    
    }
    .form_container{
        grid-template-columns: 1fr;
    }

    .edit_label{
        text-align: center;
    }
    .link_text_input{
        height: 60px;
    }
    .link_url_input{
        height: 60px;
    }
}

@media only screen and (max-width: 760px) {
    .edit_label {
        font-size: 0.9rem;
    }
}
@media only screen and (max-width: 690px) {
    .edit_container {
        grid-template-columns: 1fr 2fr;
        font-size: 0.8rem;
    }
}
@media only screen and (max-width: 600px) {
    .edit {
        width:350px;    
    }
    .link_text_input{
        height: 50px;
    }
    .link_url_input{
        height: 50px;
    }
    .edit_text_text{
        font-size: 0.8rem;
    }
}
@media only screen and (max-width: 450px) {
    .edit_icon {
        width: 15px;
        height: 15px;
        top: 0.7rem;
    right: 0.7rem;
    
    }   
    .edit {
        width:250px;    
    }
    .edit_link_container input {
        font-size:  0.9rem;
    }
}

@media only screen and (max-width: 400px) {
  
    .edit_link_container input {
        font-size:  0.7rem;
    }
    .delete_button_container {
        font-size: 0.7rem;
        width: 100px;
    }
}
    
